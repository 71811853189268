import { useState } from "react";
import DropDownJ from "../components/DropDownJ";
import MyNav from "../components/MyNav";
import FooterHome from '../components/FooterHome';
import TextBoxJ from "../components/TextBoxJ";
import { useNavigate } from "react-router";
import { animateScroll } from "react-scroll";
import axios from "axios";

export default function FormPage({ isSmallScreen }) {
    animateScroll.scrollToTop({ duration: 1, smooth: false });
    const headerSty = "text-[2rem] sm:text-[50px] mt-[7rem] font-semibold font-playfair text-left";
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        projectType: "",
        numberOfPages: "",
        keyFeatures: "",
        projectDescription: "",
        budget: "",
        timeline: "",
        maintenance: false,
        name: "",
        email: "",
        phone: "",
        additionalNotes: ""
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://g78o3mfy50.execute-api.us-east-1.amazonaws.com/Form', formData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200) {
                navigate('/success');
            } else {
                console.error('Form submission failed');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    return (
        <div className="overflow-hidden">
            <MyNav startB={true} isSmallScreen={isSmallScreen} />
            <div className="px-[30px] sm:px-[150px] mt-[7rem]">
                <p className="text-[2rem] sm:text-[50px] font-semibold font-playfair text-left">
                    Get Started with Your Project
                </p>
                <div className="px-6">
                    <p className="flex mt-[5rem] justify-center items-center text-[20px] sm:text-[36px] font-montserrat">
                        Welcome! We're excited to help you bring your project to life. Please fill out the form below with your requirements, and we'll get back to you shortly with a tailored proposal.
                    </p>
                    <form onSubmit={handleSubmit}>
                        <div className="sm:flex sm:space-x-[10rem]">
                            <DropDownJ title={"Project Type"} v1={"Static"} v2={"Fullstack"} v3={"App"} t1={"Static Website"} t2={"Fullstack Website"} t3={"Mobile Application"} name="projectType" onChange={handleChange} />
                            <DropDownJ title={"Number Of Pages"} v1={"1-5"} v2={"6-10"} v3={"11-20"} v4={"+20"} t1={"1-5"} t2={"6-10"} t3={"11-20"} t4={"+20"} name="numberOfPages" onChange={handleChange} />
                        </div>
                        <TextBoxJ title={"Key features"} placeholder={"Type out features that stand out about your project..."} height={4} name="keyFeatures" onChange={handleChange} />
                        <TextBoxJ title={"Project Description"} placeholder={"Express everything here..."} height={10} name="projectDescription" onChange={handleChange} />
                        <div className="flex space-x-[10rem]">
                            <DropDownJ title={"Budget"} v1={'under $500'} v2={"$500 - $1000"} v3={"$1000 - $5,000"} v4={"$5,000 - $10,000"} v5={"$10,000+"} t1={'under $500'} t2={"$500 - $1000"} t3={"$1000 - $5,000"} t4={"$5,000 - $10,000"} t5={"$10,000+"} name="budget" onChange={handleChange} />
                            <DropDownJ title={"Timeline"} v1={'less than a month'} v2={"1-2 months"} v3={"2-3 months"} v4={"3-6 months"} v5={"More than 6 months"} t1={'less than a month'} t2={"1-2 months"} t3={"2-3 months"} t4={"3-6 months"} t5={"More than 6 months"} name="timeline" onChange={handleChange} />
                        </div>
                        <label className="flex items-center mt-[3rem]">
                            <p className="text-[18px] sm:text-[25px] font-montserrat font-light mr-4">Would you like to have our maintenance service?</p>
                            <input type="checkbox" className="w-[2rem] h-[2rem]" name="maintenance" onChange={handleChange} />
                        </label>
                        <p className="font-montserrat text-jabelDark hover:underline hover:cursor-pointer" onClick={() => { navigate('/maintenance') }}>Don't know what that is? check it out here!</p>
                        <p className={headerSty}>
                            Contacts
                        </p>
                        <TextBoxJ placeholder={"Name"} height={1} name="name" onChange={handleChange} />
                        <TextBoxJ placeholder={"Email"} height={1} name="email" onChange={handleChange} />
                        <TextBoxJ placeholder={"Phone"} height={1} name="phone" onChange={handleChange} />
                        <p className={headerSty}>
                            Additional Notes
                        </p>
                        <TextBoxJ placeholder={"Type here..."} height={8} name="additionalNotes" onChange={handleChange} />
                        <button className="text-[32px] w-full font-playfair bg-black p-5 mt-[7rem] mb-[3rem] rounded-lg text-white active:text-gray-300 flex justify-center items-center" type="submit">Submit</button>
                    </form>
                </div>
            </div>
            <FooterHome isSmallScreen={isSmallScreen} />
        </div>
    );
}
