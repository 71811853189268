import phonePic from '../images/phoneJ.png';
import PCJ from '../images/PCJ.png';
import JabelButton from './JabelButton';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
export default function Slogan({isSmallScreen}){
    const navigate = useNavigate();
    return(
        <div className='flex justify-center items-center sm:mt-10'>

        <div className="w-screen sm:flex mt-[5rem] pb-[250px]">
        <div className=' grid'>   
        <p className=" text-[2rem] sm:text-[50px] font-semibold font-playfair text-left">
        Websites and Apps like you've never seen before.
        </p>
        <p className='text-[22px] font-montserrat font-light text-[#666666] pb-6'>You envision, and we'll create! </p>
        <JabelButton text={'Learn more...'} action={()=>{navigate('/about-us')}}/>
        </div>
        {!isSmallScreen && <div className='flex  justify-center  w-[50rem]'><img className='w-[20vw] h-auto object-contain' src={phonePic} require={phonePic}/>
        <img className='w-[20vw] h-auto object-contain' require={PCJ} src={PCJ}/></div>}
        
        </div>
        
        </div>
    )
}
