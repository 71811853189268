import Divider from "../components/Divider";
import FooterHome from "../components/FooterHome";
import MyNav from "../components/MyNav";
import WebBox from "../components/WebBox";

export default function Portfolio ({isSmallScreen}){
    return (
        <>
        <MyNav isSmallScreen={isSmallScreen} portB={true}/>
        <div className="px-[30px] sm:px-[150px] overflow-hidden mb-[3rem]">
        <p className=" font-playfair text-[55px] my-[4rem]">Our Projects</p>
            <WebBox title={"Basirah"} url={"https://basirah.org"} description={"an introductory page for the startup tech company Basirah \n\nThis project showcases our ability to create visually appealing and fast-loading websites using modern web technologies. \nThe design emphasizes simplicity and user experience, ensuring visitors can easily navigate and access information."}/>
            <Divider/>
            <WebBox title={"EasyGrade"} url={"https://easygrade.me"} description={"a comprehensive full-stack web application designed to assist educators in grading essays and assignments. \n\nThis project integrates advanced AI algorithms to analyze and grade essays efficiently, saving teachers time and providing insightful feedback to students. \nThe platform is robust and scalable, designed to handle large volumes of data and users"}/>

        </div>
        <FooterHome isSmallScreen={isSmallScreen}/>
        </>
    )
}