import { useNavigate } from "react-router"
import { animateScroll } from "react-scroll";
import NewsletterForm from "./NewsletterForm";
import SocialButtons2 from "./SocialButtons2";
import SocialButtons from './SocialButtons';
import logo from '../images/jabelonly.png'; 
import React, { useState, useEffect } from "react";

export default function FooterHome({isSmallScreen}){
    const navigate = useNavigate();
    const [disp, setDisp] = useState('flex');
    useEffect(()=>{
        if (isSmallScreen){setDisp('grid')} else{ setDisp('flex')}
    }, [isSmallScreen])
    
    return(
        <footer style={{display: "grid"}} className=" bg-[#252121] w-screen sm:h-[300px]  items-center pb-7 sm:pb-[4rem]  pt-10 sm:pl-4 overflow-hidden">
            {/* Small Screen */}
            {isSmallScreen && <div className="grid"><div className="flex space-x-[4rem] ml-9 mb-5">
            <div className="  sm:text-[18px]font-rubik">
            <p className=" text-gray-400 sm:text-[20px] hover:cursor-default">Company</p>  
            <p onClick={()=> {navigate('/about-us'); animateScroll.scrollToTop({duration: 1, smooth: false})}} className="no-underline underline-offset-[5px] hover:cursor-pointer hover:underline mt-3 text-white">About Us</p>  
            <p onClick={()=> {navigate('/privacy-policy'); animateScroll.scrollToTop({duration: 1, smooth: false})}} className="no-underline underline-offset-[5px] hover:cursor-pointer hover:underline mt-3 text-white">Privacy Policy</p>
            <p onClick={()=> {navigate('/terms-of-service'); animateScroll.scrollToTop({duration: 1, smooth: false})}} className="no-underline underline-offset-[5px] hover:cursor-pointer hover:underline mt-3 text-white">Terms Of Service</p>

            </div>
            <div className=" sm:ml-[40%] sm:text-[18px] font-rubik">
            <p className=" text-gray-400 sm:text-[20px] hover:cursor-default">Pages</p>  
            <p onClick={()=> {navigate('/'); animateScroll.scrollToTop({duration: 1, smooth: false})}} className="no-underline underline-offset-[5px] hover:cursor-pointer hover:underline mt-3 text-white">Home</p>  
            <p onClick={()=> {navigate('/maintenance'); animateScroll.scrollToTop({duration: 1, smooth: false})}} className="no-underline underline-offset-[5px] hover:cursor-pointer hover:underline mt-3 text-white">Maintenance</p>
            <p onClick={()=> {navigate('/portfolio'); animateScroll.scrollToTop({duration: 1, smooth: false})}} className="no-underline underline-offset-[5px] hover:cursor-pointer hover:underline mt-3 text-white">Portfolio</p>

            </div>
            
           
            </div>
            
            <div className="flex justify-center items-center">
            <SocialButtons2/>
            </div>
            <div className="flex justify-center items-center hover:cursor-default w-full">
                <img src={logo} className="w-[10rem] sm:w-[180px] h-auto"/>
                <p className="font-rubik sm:text-[35px] text-white">JABEL NET</p>
            </div>
            </div> }




            {/* Big Screen */}
            {!isSmallScreen &&<div className="flex"> <div className="flex justify-center items-center hover:cursor-default  w-full">
                <img src={logo} className="w-[180px] h-auto"/>
                <p className="font-rubik text-[35px] text-white">JABEL NET</p>
            </div>
            
            <div style={{
  left: '50%',
  transform: 'translate(-50%, -0%)'}} className="mr-[300px] z-10 absolute  flex items-center justify-center">
            <SocialButtons />
            </div>
            <div className=" ml-[40%] text-[18px] w-[500px] font-rubik">
            <p className=" text-gray-400 text-[20px] hover:cursor-default">Company</p>  
            <p onClick={()=> {navigate('/about-us'); animateScroll.scrollToTop({duration: 1, smooth: false})}} className="no-underline underline-offset-[5px] hover:cursor-pointer hover:underline mt-3 text-white">About Us</p>  
            <p onClick={()=> {navigate('/privacy-policy'); animateScroll.scrollToTop({duration: 1, smooth: false})}} className="no-underline underline-offset-[5px] hover:cursor-pointer hover:underline mt-3 text-white">Privacy Policy</p>
            <p onClick={()=> {navigate('/terms-of-service'); animateScroll.scrollToTop({duration: 1, smooth: false})}} className="no-underline underline-offset-[5px] hover:cursor-pointer hover:underline mt-3 text-white">Terms Of Service</p>

            </div>
            <div className=" text-[18px] w-[500px] font-rubik">
            <p className=" text-gray-400 text-[20px] hover:cursor-default">Pages</p>  
            <p onClick={()=> {navigate('/'); animateScroll.scrollToTop({duration: 1, smooth: false})}} className="no-underline underline-offset-[5px] hover:cursor-pointer hover:underline mt-3 text-white">Home</p>  
            <p onClick={()=> {navigate('/maintenance'); animateScroll.scrollToTop({duration: 1, smooth: false})}} className="no-underline underline-offset-[5px] hover:cursor-pointer hover:underline mt-3 text-white">Maintenance</p>
            <p onClick={()=> {navigate('/portfolio'); animateScroll.scrollToTop({duration: 1, smooth: false})}} className="no-underline underline-offset-[5px] hover:cursor-pointer hover:underline mt-3 text-white">Portfolio</p>

            </div></div>}
            
            
            
        
            
            
            
        </footer>
    )
}
