import React from "react"
export default function WebBox({url, title, description}){
    return (
        <div>
            
            <div className=" space-y-4">
            <p className=" font-playfair text-[32px]">{title}</p>
            <p className=" font-montserrat text-[20px]">{description.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>))}</p>
            

            <iframe 
          src={url} 
          title={title} 
          style={{width: '100%', height: '40rem', border: 'none'}}
        ></iframe>
        </div>
        </div>
    )
}