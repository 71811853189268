import { useNavigate } from 'react-router';
import { animateScroll } from "react-scroll";
import back from '../images/left-arrow.png';
export default function BackArrow({path, isSmallScreen}){
    const navigate = useNavigate();
    return(
      <>
      {!isSmallScreen && <img
      onClick={() => { navigate(`${path}`); animateScroll.scrollToTop({ duration: 1, smooth: false }) }}
      className='w-10 ml-6 mt-6 -mb-6 hover:cursor-pointer hover:w-[5rem] transition-all duration-300'
      src={back} 
      alt='Back button'
    />}

    {isSmallScreen && <img
      onClick={() => { navigate(`${path}`); animateScroll.scrollToTop({ duration: 1, smooth: false }) }}
      className='w-10 ml-6 mt-6 -mb-6'
      src={back} 
      alt='Back button'
    />}
      </>
        
    )
}