import React from 'react';
import MyNav from '../components/MyNav';
import FooterHome from '../components/FooterHome';
import { animateScroll } from 'react-scroll';

function MaintenancePage ({isSmallScreen}){
    animateScroll.scrollToTop({duration: 1, smooth: false})
    return (
    <>
    <MyNav isSmallScreen={isSmallScreen}/>
  <div className="max-w-[80rem] mx-auto p-4">
    {/* Page Header */}
    <header className="mb-8 font-playfair">
      <h1 className="text-4xl font-bold mb-3 mt-11">Maintenance Services</h1>
      <p className="text-[1.6rem] text-gray-700 font-montserrat">Keep your website or app running smoothly with our comprehensive maintenance services.</p>
    </header>
    
    {/* Introduction */}
    <section className="mb-8 font-playfair">
      <h2 className="text-[2rem] font-semibold mb-4">Why Maintenance Matters</h2>
      <p className="text-gray-700 font-montserrat text-[1.4rem]">Regular maintenance ensures your digital presence is secure, efficient, and up-to-date. Avoid downtime, improve performance, and keep your users happy.</p>
    </section>
    
    {/* Services Offered */}
    <section className="mb-8 font-playfair">
      <h2 className="text-[2rem] font-semibold mb-4">Our Services</h2>
      <ul className="list-disc list-inside text-gray-700 text-[1.4rem] font-montserrat">
        <li className="mb-2">Regular updates</li>
        <li className="mb-2">Security patches</li>
        <li className="mb-2">Performance optimizations</li>
        <li className="mb-2">Bug fixes</li>
        <li className="mb-2">Content updates</li>
        <li className="mb-2">Custom maintenance plans</li>
        <li className='mb-2'>AI security checker</li>
      </ul>
    </section>
    
    {/* Benefits */}
    <section className="mb-8 font-playfair">
      <h2 className="text-[2rem] font-semibold mb-4">Benefits</h2>
      <p className="text-gray-700 text-[1.4rem] font-montserrat mb-2">Choosing our maintenance services offers numerous benefits:</p>
      <ul className="list-disc list-inside text-gray-700 text-[1.4rem] font-montserrat">
        <li className="mb-2">Improved security</li>
        <li className="mb-2">Better performance</li>
        <li className="mb-2">Peace of mind</li>
        <li className="mb-2">Professional support</li>
        <li className="mb-2">Up-to-date content</li>
      </ul>
    </section>
    
    {/* Pricing 
    <section className="mb-8 font-playfair">
      <h2 className="text-[2rem] font-semibold mb-4">Pricing</h2>
      <p className="text-gray-700 text-[1.4rem] font-montserrat mb-4">We offer flexible maintenance plans to suit your needs:</p>
      <ul className="list-disc list-inside text-gray-700 text-[1.4rem] font-montserrat">
        <li className="mb-2"><strong>Basic Plan:</strong> $100/month</li>
        <li className="mb-2"><strong>Standard Plan:</strong> $200/month</li>
        <li className="mb-2"><strong>Premium Plan:</strong> $300/month</li>
      </ul>
    </section>*/}
    
    
    {/* Call to Action */}
    <section className="mb-8 font-playfair">
      <h2 className="text-[2rem] font-semibold mb-4">Get in Touch</h2>
      <p className="text-gray-700 text-[1.4rem] font-montserrat mb-4">Ready to ensure your digital presence is always in top shape? Contact us today to learn more about our maintenance services.</p>
      <p className="text-gray-700 text-[1.4rem] font-montserrat mb-2"><strong>Email:</strong> contact@jabelnet.com</p>
      <p className="text-gray-700 text-[1.4rem] font-montserrat"><strong>Phone:</strong> +968 9637 7997</p>
    </section>
    
    {/* FAQs */}
    <section className="mb-8 font-playfair">
      <h2 className="text-[2rem] font-semibold mb-10 mt-[4rem]">Frequently Asked Questions</h2>
      <div className="mb-4">
        <h3 className="text-[2rem] font-semibold mb-2">What does the maintenance service include?</h3>
        <p className="text-gray-700 text-[1.4rem] font-montserrat">Our maintenance service includes regular updates, security patches, performance optimizations, bug fixes, and content updates.</p>
      </div>
      <div>
        <h3 className="text-[2rem] font-semibold mb-2">How often will my site be updated?</h3>
        <p className="text-gray-700 text-[1.4rem] font-montserrat">We perform updates on a monthly basis, or more frequently if urgent security patches are needed.</p>
      </div>
    </section>
  </div>
  <FooterHome isSmallScreen={isSmallScreen}/>
  </>
);
}
export default MaintenancePage;
