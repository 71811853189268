export default function JabelButton({action, text, icon, final}){
    return(
        <>
        {icon && <button onClick={action} className=" w-[17rem] text-white font-montserrat bg-[#6B8E23]  py-4 rounded hover:bg-[#556B2F] duration-300 ease-in-out flex justify-center items-center">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z"/></svg><p>{text}</p>
        </button>}

        {!icon && <button onClick={action} className=" w-[17rem] text-white font-montserrat bg-[#6B8E23]  py-4 rounded hover:bg-[#556B2F] duration-300 ease-in-out flex justify-center items-center">
        <p>{text}</p>
        </button>}
        </> 
    )
}