export default function FinalForm (){
    let labelSt = 'font-montserrat text-[17px] sm:text-[24px]'
    return (
        <div className="w-screen sm:flex sm:mb-12">
            <div className="sm:w-[50%] pb-[1rem] sm:pb-[90px]">
                <p className="font-montserrat text-[20px] sm:text-[40px]">Get in touch, let's create magic<br/> together!</p>
                <p className="text-[16px] font-montserrat sm:text-[24px] pt-[30px]">Email: service@jabelnet.com<br></br>Phone: +968 9637 7997</p>
            </div>
            <div>
            <form action="https://formspree.io/f/mayrrgly"
  method="POST" className="grid">
  <label className={labelSt}>
    Your Email:<br></br>
    <textarea cols={30} rows={1} className="rounded"/>
  </label>
  <div className=" mt-3 sm:mt-10 justify-center items-center mb-5">
  <label className={labelSt}>
    Your Message: <br></br>
    <textarea cols={30} rows={4} className="rounded"  name="message"></textarea>
  </label>
  </div>
  <button type="submit" className="w-[150px] text-white font-montserrat bg-[#6B8E23] px-8 py-4 rounded hover:bg-[#556B2F] duration-300 ease-in-out mb-5 sm:mb-0">Send</button>
</form>
            </div>
        </div>
    )
}
