import JabelButton from "../components/JabelButton"
import MyNav from "../components/MyNav"
import Divider from "../components/Divider"
import FooterHome from "../components/FooterHome"
import PersonCard from "../components/PersonCard"
import FrameworkBox from "../components/FrameworkBox"
import reactlogo from '../images/reactLogo.png';
import tailwindlogo from '../images/tailwindlogo.jpg'
import awslogo from '../images/awslogo.jpg';
import imge from '../images/imgLoc.png';
import { useNavigate } from "react-router";
import { animateScroll } from "react-scroll"

export default function About({isSmallScreen}){
    const navigate = useNavigate();
    animateScroll.scrollToTop({duration: 1, smooth: false})
    return(
        <>
        <MyNav aboutB={true} isSmallScreen={isSmallScreen}/>
        <div className="px-[30px] sm:px-[150px]">

            <p className="text-[2rem] sm:text-[70px] font-playfair font-semibold py-[7rem]">
                About Us
            </p>
            
                
            <JabelButton icon={true} text={'Get started right away!'} action={()=>{navigate('/get-started')}}/>
            
            <Divider/>
            <p className="text-[2rem] sm:text-[50px] font-playfair pt-[7rem] pb-[3rem]">Our Story</p>
            
                <p className="sm:px-[4rem] text-[1.5rem] sm:text-[30px] font-montserrat pb-[6rem]">We are an Omani-based company, established in 2024 by two individuals with a deep passion for web and application development.
</p>
            <Divider/>
            <div className=" flex justify-center items-center">
                <p className="px-[4rem] text-[2rem] sm:text-[50px] pb-8 font-playfair font-semibold">Our Team</p>
            </div>
            <div className="flex">
                <PersonCard name={"Hamzah AlNofli"} pos={"Lead Front-End Developer & Co-Founder"} imge={imge}/>
                <PersonCard name={"Salim Al Buraiki"} pos={"Lead Back-End Developer & Co-Founder"} imge={imge}/>
            </div>
            <Divider/>
            <div>
                <p className=" sm:px-[5rem] py-[8rem] text-[28px] font-montserrat font-light">
                We at Jabel Net have mastered the following frameworks to complete our tasks, bringing you the best of each world
                </p>
            </div>
            
            
            
        </div>
        <FrameworkBox isSmallScreen={isSmallScreen} bgCol={'#222222'} text={"We use React JS to handle the front-end in any way you'd need, including dynamic content and much more!"} title={"React JS / React Native"} twoP={true} image={reactlogo}/>
        <FrameworkBox isSmallScreen={isSmallScreen} bgCol={'#151D2C'} text={"We also use Tailwind to style your websites & animate them, making things more lively and colorful, as per your needs."} title={'Tailwind CSS'} image={tailwindlogo}/> 
        <FrameworkBox isSmallScreen={isSmallScreen} bgCol={'#232E3E'} text={"To make your website alive on the internet, we use a cloud-based server called AWS, it handles your database along with hosting it for you and whomever accesses it."} title={"AWS Cloud"} image={awslogo}/>
        <div className="px-[30px] sm:px-[150px]">
        <p className="text-[2rem] sm:text-[50px] font-playfair pt-[7rem] pb-[3rem]">What We Offer:</p>
            
            <ul className="sm:px-[4rem] text-[1.5rem] sm:text-[30px] font-montserrat space-y-8 list-disc pb-[4rem]">
                <li>
                Static websites: like ones you'd see as portfolios for companies
                </li>
                <li>
                Fullstack websites: like e-shops or websites where signing up is a thing.
                </li>
                <li>
                IOS & Android apps: with all the functionality and designs that come with it, depending on your need.
                </li>
            </ul>
            <Divider/>

            <p className="text-[2rem] sm:text-[50px] font-playfair pt-[7rem] pb-[3rem]">Why Choose Us?</p>
            
            <p className="sm:px-[4rem] text-[1.5rem] sm:text-[30px] font-montserrat pb-[6rem]">We turn our experience and passion in our field into products made with care and love, providing our clients with the best experience possible is our #1 priority; as we will focus heavily on our client's goal and everything after that's done accordingly.
</p>
                
            <Divider/>
            <p className="text-[2rem] sm:text-[50px] font-playfair pt-[7rem] pb-[3rem]">Testimonials:</p>
            
            <ul className="sm:px-[4rem] text-[1.5rem] sm:text-[30px] font-montserrat space-y-10 list-disc pb-[4rem]">
                <li className="border-l-4 border-blue-800 pl-4 italic">
                "Working with Hamzah on our website was an absolute pleasure. His creativity and dedication to inclusivity truly stood out, making our site not only engaging but also accessible for visually impaired individuals. Thank you, Hamzah, for bringing our vision to life so beautifully." - <span className=" not-italic" style={{ fontWeight: "bold"}}>Basirah</span>
                </li>
                
                
            </ul>
            <Divider/>
            <div className="flex justify-center items-center pb-[3rem] pt-[1rem]">
            <JabelButton action={()=>{navigate('/get-started')}} text={'Get started'} final={true} icon={true} />
            </div>
            </div>
            
        <FooterHome isSmallScreen={isSmallScreen}/>

        </>
    )
}
