import React from 'react';
import logo from '../images/JabelOP.png';
import BackArrow from '../components/BackArrow';

function TermsOfService ({isSmallScreen}){
  const header = {
    fontFamily: 'Times New Roman',
    fontSize: "22px",
    opacity: 1
  }

  const header1 = {
    fontFamily: 'Times New Roman',
    fontSize: "22px",
    marginBottom: '1rem',
    marginTop: '1rem'
  }


  return (
    <div style={{
      backgroundImage: `url(${logo})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: '60%'
    }}> 
      <BackArrow path={'/'} isSmallScreen={isSmallScreen}/>
      <div className="mt-[100px] mx-3 sm:mx-100 sm:mt-100 sm:m-[100px]">
        <h1 className="font-bold text-[32px] mb-9" style={header1}>Jabel Net Terms of Service<br /></h1>
        <p style={header}>
          Welcome to Jabel Net! By using our services, including any custom websites or applications we develop for you, you agree to comply with and be bound by the following terms and conditions. Please read these terms carefully.
        </p>
        <br />
        <h2 style={header1} className="font-bold">1. Acceptance of Terms</h2>
        <p style={header}>
          By accessing or using our services, you agree to these Terms of Service. If you do not agree with any part of these terms, you must not use our services.
        </p>
        <h2 style={header1} className="font-bold">2. Description of Services</h2>
        <p style={header}>
          Jabel Net provides web development services, including the creation of custom websites and applications tailored to your specific needs. This includes design, development, maintenance, AI integration, and hosting solutions.
        </p>
        <h2 style={header1} className="font-bold">3. User Responsibilities</h2>
        <p style={header}>
          Users are responsible for maintaining the confidentiality of their account information and for all activities that occur under their account. Users must provide accurate and complete information when creating an account and using our services.
        </p>
        <h2 style={header1} className="font-bold">4. Intellectual Property</h2>
        <p style={header}>
          All content and materials created by Jabel Net, including text, graphics, website code, images, and logos, are the intellectual property of Jabel Net and are protected by applicable copyright and trademark laws.
        </p>
        <h2 style={header1} className="font-bold">5. Privacy Policy</h2>
        <p style={header}>
          Your use of our services is also governed by our Privacy Policy, which outlines how we collect, use, and protect your personal information. By using our services, you consent to the practices described in our Privacy Policy.
        </p>
        <h2 style={header1} className="font-bold">6. Service Availability</h2>
        <p style={header}>
          While we strive to ensure that our services are available at all times and to deliver error-free solutions, we do not guarantee that the services will be uninterrupted or error-free. We may also need to perform maintenance on our services, which may result in temporary service interruptions.
        </p>
        <h2 style={header1} className="font-bold">7. Limitation of Liability</h2>
        <p style={header}>
          Jabel Net is not liable for any direct, indirect, incidental, consequential, or punitive damages arising from the use of our services. This includes, but is not limited to, loss of data or profits, even if Jabel Net has been advised of the possibility of such damages.
        </p>
        <h2 style={header1} className="font-bold">8. Termination</h2>
        <p style={header}>
          We reserve the right to terminate or suspend your access to our services at any time, without prior notice or liability, in the event of a breach of these Terms of Service or any other applicable policies.
        </p>
        <h2 style={header1} className="font-bold">9. Modifications to Terms</h2>
        <p style={header}>
          Jabel Net reserves the right to modify these Terms of Service at any time. Any changes will be effective immediately upon posting on our website, and you will be notified. Your continued use of our services after any changes constitutes your acceptance of the new terms.
        </p>
        <h2 style={header1} className="font-bold">10. Governing Law</h2>
        <p style={header}>
          These Terms of Service are governed by and construed in accordance with the laws of Oman. Any disputes arising out of or related to these terms will be subject to the exclusive jurisdiction of the courts of Oman.
        </p>
        <h2 style={header1} className="font-bold">11. Contact Information</h2>
        <p style={header}>
          If you have any questions about these Terms of Service, please contact us at:<br />
          Jabel Net LLC<br />
          Email: legal@jabelnet.com<br />
          Phone: +968 9637 7997<br />
          Address: Muscat, Oman
        </p>
        <p style={header} className='mt-[2rem]'>
          By using Jabel Net services, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service. Thank you for choosing Jabel Net!
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;
