import ios from '../images/ios.png';
import android from '../images/android.png';
export default function HorizontalCard({headerText, subText, image, www, ltr, onePic, isSmallScreen, alt}){
    let header = 'text-[2rem] sm:text-[42px] text-center sm:text-left font-playfair';
    let sub = 'text-[1.3rem] sm:text-[18px] text-center sm:text-left pt-[20px] font-montserrat sm:font-light';
    return ( 
        <>
        {/* Big screen */}
        {!isSmallScreen && <>
            {ltr && <div className="flex my-[4rem] sm:my-[7rem] snap-mandatory snap-y">
        <div className="grid w-[50%] snap-center">
            <p className={header}>{headerText}</p>
            <p className={sub} >{subText}</p>
        </div>

        <div className='w-[50%] snap-center flex justify-center items-center'>
            <div className='grid snap-center'>
            <img src={image} alt={alt} className='w-[200px]'/>
            {www && <div className='w-[100%] hover:cursor-default flex justify-center items-center font-bold text-[30px] underline'>
            <p>www.com</p>
            </div>}
            </div>
        </div>
        </div>}

        {ltr === false && <div className="flex snap-mandatory snap-y my-[7rem]">
        

        <div className='w-[40%]  sm:w-[50%] snap-y snap-start flex justify-center items-center'>
            <div className='grid snap-y snap-start'>
            {onePic &&     
            <img src={image} className='w-[100px] sm:w-[200px]'/>
            }
            {onePic == false && <div className='sm:flex'><img src={ios} className='w-[100px] sm:w-[200px] h-[100px] sm:h-[200px]'/> <img src={android} className='w-[100px] sm:w-[200px] h-[100px] sm:h-[200px]'/></div>}
            {www && <div className='w-[100%] flex justify-center items-center font-bold text-[30px] underline'>
            <p className=' hover:cursor-default'>www.com</p>
            </div>}
            </div>
        </div>
        <div className="grid w-[50%]">
            <p className={header}>{headerText}</p>
            <p className={sub}>{subText}</p>
        </div>
        </div>}
        </>}
        


        {/* Small screen */}
        {isSmallScreen && 
        
        <div className="grid my-[2rem] sm:my-[7rem] snap-mandatory snap-y">
            <div className=' snap-center flex justify-center items-center'>
            <div className='grid snap-center text-center'>
            <img src={image} className='w-[10rem]'/>
            
            </div>
        </div>
        <div className="grid snap-center">
            <p className={header}>{headerText}</p>
            <p className={sub} >{subText}</p>
        </div>

        
        </div>

       }
        </>
        
    )
}