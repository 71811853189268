export default function FrameworkBox({twoP, text, title, image, bgCol, isSmallScreen}){
    return(<>
    
        {!isSmallScreen &&
        <div style={{backgroundColor:bgCol}} className="flex py-[2rem] sm:pl-[10rem] w-screen">
            <img src={image} className='w-[10rem] sm:w-[300px] h-[10rem] sm:h-auto'/>
            <div className=' grid pl-[1rem] sm:pl-[20rem]'>
                <p className=' font-rubik text-[34px] sm:text-[46px] text-white'>{title}
</p>
            <p className='text-white font-montserrat sm:text-[22px] pr-4'>
                {text}<br/>
            </p>
            {twoP && <p className='text-white font-montserrat sm:text-[22px] pr-4'>
            <br/>And React Native (which are both based off of one system of code) to create a native feeling app for both IOS and Android at once.
            </p>}
             
            </div>
        </div>}
        {isSmallScreen &&
        <div style={{backgroundColor:bgCol}} className=" grid py-[2rem] sm:pl-[10rem] w-screen">
            <div className="flex justify-center items-center">
            <img src={image} className='w-[10rem] justify-center items-center sm:w-[300px] h-[10rem] sm:h-auto'/>
            </div>
            <div className=' grid pl-[1rem] sm:pl-[20rem]'>
                <p className=' font-rubik text-[34px] sm:text-[46px] text-white'>{title}
</p>
            <p className='text-white font-montserrat sm:text-[22px] pr-4'>
                {text}<br/>
            </p>
            {twoP && <p className='text-white font-montserrat sm:text-[22px] pr-4'>
            <br/>And React Native (which are both based off of one system of code) to create a native feeling app for both IOS and Android at once.
            </p>}
             
            </div>
        </div>}
        </>
    )
}