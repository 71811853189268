import React, { useState } from 'react';
import axios from 'axios';  

const Chatbot = () => {
    const [userInput, setUserInput] = useState('');
    const [chatbotResponse, setChatbotResponse] = useState('');
    const [loading, setLoading] = useState(false);

    const apiUrl = 'https://taprpf7477.execute-api.us-east-1.amazonaws.com/Chatbot';  

    const sendMessage = async () => {
        if (userInput.trim() === '') return;  

        setLoading(true);
        try {
            const response = await axios.post(apiUrl, {
                prompt: userInput
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            setChatbotResponse(response.data.response);
        } catch (error) {
            console.error('Error:', error);
            setChatbotResponse("Error: Could not get response from chatbot.");
        }
        setLoading(false);
    };

    return (
        <div>
            <h1>Chat with our Bot</h1>
            <div>
                <textarea
                    rows="4"
                    cols="50"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    placeholder="Type your message here"
                ></textarea>
                <br />
                <button onClick={sendMessage} disabled={loading}>
                    {loading ? 'Sending...' : 'Send'}
                </button>
            </div>

            <div>
                <h2>Chatbot Response:</h2>
                <div>{chatbotResponse}</div>
            </div>
        </div>
    );
};

export default Chatbot;
