import { useInView } from 'react-intersection-observer';
import { useState, useEffect } from 'react';

const AnimateInView = ({ children, duration = 400 }) => {
  const { ref, inView } = useInView({
    threshold: 1.0,
  });
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    if (inView) {
      setOpacity(1);
    } 
  }, [inView]);

  return (
    <div
      ref={ref}
      style={{
        opacity,
        transition: `opacity ${duration}ms`,
      }}
    >
      {children}
    </div>
  );
};
export default AnimateInView