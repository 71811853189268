export default function DropDownJ ({title, v1, v2, v3, v4, v5, t1, t2, t3, t4, t5}){
    return(
        <div className="mt-[5rem]">
          
                <label className="grid text-[26px] font-montserrat font-light">
                    <p className="pl-3">{title}</p>
                    <select type="drop" className=" rounded form-select text-[20px] w-[20rem] mt-3 p-3">
                        <option value={v1}>{t1}</option>
                        <option value={v2}>{t2}</option>
                        <option value={v3}>{t3}</option>
                        {t4 && <option value={v4}>{t4}</option>}
                        {t5 && <option value={v5}>{t5}</option>}
                    </select>
                </label>
        </div>
         )
}