import MyNav from '../components/MyNav';
import '../css/index.css';
import logo from '../images/logo.png';
import jabel from '../images/jabel_po.png';
import TextBox1 from '../components/TextBox1';
import Slogan from '../components/Slogan';
import { CardFooter } from 'react-bootstrap';
import FooterHome from '../components/FooterHome';
import JabelButton from '../components/JabelButton';
import Divider from '../components/Divider';
import HorizontalCard from '../components/HorizontalCard';
import web from '../images/web.png';
import iosandroid from '../images/iosandroid.png';
import shield from '../images/shield.png';
import brush from '../images/brush.png';
import FinalForm from '../components/FinalForm';
import AnimatedDiv from '../components/AnimatedDiv'
import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { animateScroll } from 'react-scroll';


export default function Home({isSmallScreen}){
    animateScroll.scrollToTop({duration: 1, smooth: false})
          
      return(
        <>
        <MyNav homeB={true} isSmallScreen={isSmallScreen}/>
        <div className='px-[30px] sm:px-[150px] overflow-hidden'>
        <Slogan isSmallScreen={isSmallScreen}/>
        <div className=' flex justify-center items-center  text-[2.2rem] sm:text-[42px] font-montserrat font-light'>
            <p>What We Provide</p>
        </div>
        <Divider/>
        <AnimatedDiv>
        <HorizontalCard isSmallScreen={isSmallScreen} ltr={true} image={web} www={true} headerText={'Websites, across your imagination.'} subText={'From basic portfolios, to e-commerce, to even AI-infused websites!'}/>
        </AnimatedDiv>
        <Divider/>
        <AnimatedDiv>
        <HorizontalCard isSmallScreen={isSmallScreen} onePic={false} headerText={'iPhone and Android applications, at once.'} subText={'Fitting every need for your idea, aligned with your specified market.'} ltr={false} image={iosandroid}/>
        </AnimatedDiv>
        <Divider/>
        <AnimatedDiv>
        <HorizontalCard isSmallScreen={isSmallScreen} onePic={true} headerText={'Securing your app/website and maintenance'} subText={'Nobody enjoys an unsafe experience, especially the creator of said experience.'} ltr={true} image={shield}/>
        </AnimatedDiv>
        <Divider/>
        <AnimatedDiv>
        <HorizontalCard isSmallScreen={isSmallScreen} onePic={true} ltr={false} headerText={"Don't worry, we can design for you too."} subText={'We are capable of bringing your idea into life, through creative designs; logos, website designs and much more!'} image={brush}/>
        </AnimatedDiv>
        <Divider/>
        <FinalForm/>
        </div>
        <FooterHome isSmallScreen={isSmallScreen}/>
        </>
    )
}
