import '../css/App.css';
import '../css/index.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import About from './About';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import { useState, useEffect } from 'react';
import FormPage from './FormPage';
import SuccessPage from './SuccessPage';
import Portfolio from './Portfolio';
import MaintenancePage from './Maintenance';
import Chatbot from './Chatbot';
function App() {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 640);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 639px)');
    const handleMediaChange = (e) => setIsSmallScreen(e.matches);

    // Initial check
    handleMediaChange(mediaQuery);

    // Set up event listener
    mediaQuery.addEventListener('change', handleMediaChange);

    // Clean up event listener on component unmount
    return () => mediaQuery.removeEventListener('change', handleMediaChange);
  }, []);

  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home isSmallScreen={isSmallScreen}/>}/>
        <Route path='/privacy-policy' element={<PrivacyPolicy isSmallScreen={isSmallScreen}/>}/>
        <Route path='/terms-of-service' element={<TermsOfService isSmallScreen={isSmallScreen}/>}/>
        <Route path='/about-us' element={<About isSmallScreen={isSmallScreen}/>}/>
        <Route path='/get-started' element={<FormPage isSmallScreen={isSmallScreen}/>}/>
        <Route path='/success' element={<SuccessPage/>}/>
        <Route path='/portfolio' element={<Portfolio isSmallScreen={isSmallScreen}/>} />
        <Route path='/maintenance' element={<MaintenancePage isSmallScreen={isSmallScreen}/>} />
        <Route path='/chatbot' element={<Chatbot />} />

      </Routes>
    </Router>
  );
}

export default App;

//import { Oval } from 'react-loader-spinner';
