import logo from '../images/JabelOP.png'
import  BackArrow from '../components/BackArrow.jsx'
export default function PrivacyPolicy({isSmallScreen}){
    const header = {
        fontFamily: 'Times new Roman',
        fontSize: '22px',
        opacity: 1
    }

    const header1 = {
        fontFamily: 'Times new Roman',
        fontSize: '22px',
        marginBottom: '1rem',
        marginTop: '1rem'
    }

    return(
        
        <div style={{backgroundImage: `url(${logo})`,
        backgroundRepeat: 'no-repeat', 
        backgroundPosition: 'center',
        backgroundSize: '60%'
        }} >
        <BackArrow path={'/'} isSmallScreen={isSmallScreen}/>
        <div className="mt-[100px] sm:mt-100 mx-3 sm:mx-100 sm:m-[100px]">
        <h1 className="font-bold text-[32px] mb-9" style={header1}>Jabel Net Privacy Policy<br/></h1>
        <p style={header}>At Jabel Net, we are committed to protecting your privacy and ensuring that your personal
information is handled in a safe and responsible manner. This Privacy Policy outlines how we
collect, use, and protect your information when you use our services.</p>
        <br/>
        <h2 style={header1} className="font-bold">1. Information we collect</h2>
        <p style={header}>We collect information that you provide directly to us when you use our services. This includes:</p>
        <ul className="list-disc ml-[4rem]">
            <li style={header}>
            Personal information such as your name, email address, phone number, and postal
address.
            </li >
            <li style={header}>
            Account information such as your username, password, and other registration details.
            </li>
        </ul>

        <h2 style={header1} className="font-bold">2. How We Use Your Information</h2>
        <p style={header}>We use the information we collect to:</p>
        <ul className="list-disc ml-[4rem]">
            <li style={header}>
            Provide, operate, and maintain our services.
            </li >
            <li style={header}>
            Process and complete transactions.
            </li>
            <li style={header}>
            Improve, personalize, and expand our services.            </li>
            <li style={header}>
            Understand and analyze how you use our services.            </li>
            <li style={header}>
            Develop new products, services, features, and functionality.            </li>
            <li style={header}>
            Communicate with you, either directly or through one of our partners, including customer
service, to provide you with updates and other information relating to the service, and for
marketing and promotional purposes.            </li>
            <li style={header}>
            Find and prevent fraud.            </li>
            <li style={header}>
            Comply with legal obligations.
            </li>
            <li style={header}>
            Process and complete transactions.
            </li>
            
        </ul>
        <h2 style={header1} className="font-bold">3. Sharing Your Information</h2>
        <p style={header}>We do not share your personal information with third parties except in the following circumstances:</p>
        <ul className="list-disc ml-[4rem]">
            <li style={header}>
            With your consent.
            </li >
            <li style={header}>
            With our service providers who need access to such information to carry out work on our
behalf.
            </li >
            <li style={header}>
            To comply with legal obligations, such as responding to a subpoena or court order.
            </li >
            <li style={header}>
            To protect and defend our rights or property.
            </li > 
            <li style={header}>
            In connection with a business transfer, such as a merger or acquisition.            </li >
            </ul>
            <h2 style={header1} className="font-bold">4. Data Security</h2>
        <p style={header}>We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method f transmission over the internet, or method of electronic storage, is 100% secure, and we cannot guarantee absolute security.</p>
        <h2 style={header1} className="font-bold">5. Your Rights</h2>
        <p style={header}>You have the right to access, update, or delete your personal information. You can do this by contacting us directly. You also have the right to object to or restrict certain types of processing of your personal information.</p>
        <h2 style={header1} className="font-bold">6. Cookies and Tracking Technologies</h2>
        <p style={header}>We use cookies and similar tracking technologies to track the activity on our service and hold certain information. Cookies are files with a small amount of data that are stored on your device. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our service.</p>
        <h2 style={header1} className="font-bold">7. Third-Party Services</h2>
        <p style={header}>Our services may contain links to third-party websites or services that are not owned or controlled by Jabel Net. We have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party websites or services. We strongly advise you to review the privacy policy of every site you visit.</p>
        <h2 style={header1} className="font-bold">8. Changes to This Privacy Policy</h2>
        <p style={header}>Jabel Net reserves the right to modify this Privacy Policy at any time. Any changes will be effective immediately upon posting on our website, and you will be notified. Your continued use of our services after any changes constitutes your acceptance of the new terms.</p>
        <h2 style={header1} className="font-bold">9. Contact Us</h2>
        <p style={header} className='mb-[2rem]'>If you have any questions about this Privacy Policy, please contact us at:</p>
        <p style={header}>Jabel Net LLC<br/>Email: legal@jabelnet.com<br/>Phone: +968 9637 7997<br/>Address: Muscat, Oman
</p>      
        <p style={header} className='mt-[2rem]'>By using Jabel Net services, you acknowledge that you have read, understood, and agree to be bound by this Privacy Policy. Thank you for trusting Jabel Net with your personal information.</p>
        </div>
        </div>
    )
}