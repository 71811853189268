import { useNavigate } from "react-router";
import { animateScroll } from "react-scroll";
export default function SuccessPage(){
    let textSty = 'text-[1.4rem] font-montserrat font-light';
    const navigate = useNavigate()
    return(
        <div className="px-[30px] sm:px-[28rem] ">
            <p className=" text-jabelGreen text-center font-playfair text-[50px] mt-[4rem] font-semibold">
            Success! Your Request Has Been Submitted
            </p>
            <p style={{marginTop: '4rem'}} className={textSty}>Thank you for getting started with Jabel Net! We have received your project details and will review your submission shortly. <br/> </p>
            <p className={textSty}><br/>One of our team members will reach out to you within 2-3 business days with a tailored proposal.</p>
            <p className=" font-playfair text-[50px] mt-[4rem] font-semibold">
            In the meantime...
            </p> 
            <p style={{marginTop: '2rem'}} className={textSty}>if you have any questions or need to provide additional information, please feel free to contact us at service@jabelnet.com or call us at +96896377997<br/></p>
            <p className={textSty}><br/>We look forward to working with you and bringing your project to life!</p>
            <div className="flex items-center justify-center">
            <button className=" bg-jabelGreen px-[10rem] py-[1rem] text-[26px] font-montserrat rounded my-[2rem] self-center text-white items-center flex" onClick={()=> {navigate('/');animateScroll.scrollToTop({duration: 1, smooth: false})}}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" className=" rotate-180" fill="#e8eaed"><path d="M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z"/></svg><p>Home</p>
</button>
            </div>
        </div>
    )
}