import Container from 'react-bootstrap/Container';
import { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../images/Jabel2.png';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-bootstrap';
 
export default function MyNav ({homeB, aboutB, startB, portB, isSmallScreen}){
    let brand, boldBrand;

     if (!isSmallScreen){
        brand = 'mx-4 hover:text-[#6B8E23]';
        boldBrand = 'mx-4 font-bold hover:text-[#6B8E23]';
    } else {
        brand = 'mx-2 hover:text-[#6B8E23]';
        boldBrand = 'mx-2 font-bold hover:text-[#6B8E23]';
    }
    const navigate = useNavigate();
    if (homeB){
        aboutB = false;
        startB = false;
        portB = false;
    } else if (aboutB){
        homeB = false;
        startB = false;
        portB = false;
    } else if (startB){
        homeB = false;
        aboutB = false;
        portB = false;
    } else if(portB){
        homeB = false;
        aboutB = false;
        startB = false;
    } else{
        homeB = false;
        startB = false;
        aboutB = false;
    }

    

    return (
        <Navbar fixed='top' expand='lg' className='flex shadow-sm hover:shadow-lg hover:ease-in duration-200 w-screen bg-[rgb(37,33,33)]'>
            
            <Container className='justify-center flex w-screen pl-2 font-montserrat h-[5rem]'>
                <img src={logo} className='w-[50px] sm:w-[64px] h-[50px] sm:h-[64px] absolute left-3 top-3 sm:top-2 hover:cursor-pointer' onClick={function N () {navigate('/')}}/>
                <Nav className='me-auto items-center justify-end flex absolute right-3 top-7 sm:top-5  text-[14px] sm:text-[20px] text-white '>
                    {!homeB && !isSmallScreen && <Nav.Link className={brand} href='/'>
                        Home
                    </Nav.Link>}
                    {homeB&&  !isSmallScreen && <Nav.Link className={boldBrand} href='/'>
                        Home
                    </Nav.Link>}
                    {!aboutB && <Nav.Link className={brand} href='/about-us'>
                        About Us
                    </Nav.Link>}
                    {aboutB && <Nav.Link className={boldBrand} href='/about-us'>
                        About Us
                    </Nav.Link>}
                    {!portB && <Nav.Link className={brand} href='/portfolio'>
                        Portfolio
                    </Nav.Link>}
                    {portB && <Nav.Link className={boldBrand} href='/portfolio'>
                    Portfolio
                    </Nav.Link>}  
                    {!startB && <Nav.Link className={brand} href='/get-started'>
                        Get Started
                    </Nav.Link>}
                    {startB && <Nav.Link className={boldBrand} href='/get-started'>
                    Get Started
                    </Nav.Link>}   
                                   
                </Nav>
            </Container>

        </Navbar>
    )
}
